import { CLEARANCE } from '../../../utils/constants';

export default [
  {
    url: '/home',
    name: 'Dashboard',
    slug: 'dashboard',
    icon: 'MonitorIcon',
    clearance: []
  },
  {
    url: '/branches',
    name: 'Branches',
    slug: 'branches',
    icon: 'HomeIcon',
    clearance: [CLEARANCE.SUPER_ADMIN]
  },
  {
    url: '/invoices',
    name: 'Invoices',
    slug: 'invoices',
    icon: 'ListIcon',
    clearance: [CLEARANCE.SUPER_ADMIN, CLEARANCE.BRANCH_ADMIN, CLEARANCE.FINANCE]
  },
  {
    url: '/customers',
    name: 'Customers',
    slug: 'customers',
    icon: 'UsersIcon',
    clearance: [CLEARANCE.SUPER_ADMIN, CLEARANCE.BRANCH_ADMIN, CLEARANCE.FINANCE]
  },
  {
    url: '/tickets',
    name: 'Tickets',
    slug: 'tickets',
    icon: 'LayersIcon',
    clearance: [CLEARANCE.BRANCH_ADMIN, CLEARANCE.FINANCE]
  },
  {
    url: '/technicians',
    name: 'Technicians',
    slug: 'technicians',
    icon: 'FeatherIcon',
    clearance: [CLEARANCE.SUPER_ADMIN, CLEARANCE.BRANCH_ADMIN]
  }
];
