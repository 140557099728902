<template>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <span>
      <!--  -->
    </span>
    <span class="md:flex hidden items-center">
      <feather-icon
        icon="CodeIcon"
        svg-classes="stroke-current text-primary w-6 h-6"
        class="ml-2"
      />
      <span>
        developed by
        <a href="https://archisdi.me" target="_blank" rel="nofollow">Archisdi</a>
      </span>
    </span>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  props: {
    classes: {
      type: String
    }
  }
};
</script>
