<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />

        <!-- <bookmarks :navbar-color="navbarColor" v-if="windowWidth >= 992" /> -->

        <vs-spacer />

        <!-- <search-bar /> -->

        <!-- <notification-drop-down /> -->

        <profile-drop-down />
      </vs-navbar>
    </div>
  </div>
</template>


<script>
// import Bookmarks from './components/Bookmarks.vue';
// import SearchBar from './components/SearchBar.vue';
// import NotificationDropDown from './components/NotificationDropDown.vue';
import ProfileDropDown from './components/ProfileDropDown.vue';

export default {
  name: 'TheNavbarVertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  components: {
    // Bookmarks,
    // SearchBar,
    // NotificationDropDown,
    ProfileDropDown
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.base.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.base.verticalNavMenuWidth;
    },
    textColor() {
      return { 'text-white': (this.navbarColor !== '#10163a' && this.$store.state.base.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.base.theme !== 'dark') };
    },
    windowWidth() {
      return this.$store.state.base.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth === 'default') return 'navbar-default';
      if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced';
      if (this.verticalNavMenuWidth) return 'navbar-full';
      return '';
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    }
  }
};
</script>
