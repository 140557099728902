<template>
  <div class="the-navbar__user-meta flex items-center" v-if="$store.getters.getName">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">
        {{ $store.getters.getName }}
      </p>
      <small v-if="$store.getters.getBranch">{{ $store.getters.getBranch }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img key="onlineImg" :src="require('@/assets/images/portrait/small/account.png')" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block">
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/profile')">
            <feather-icon icon="UserIcon" svg-classes="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li> -->

          <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="SettingsIcon" svg-classes="w-4 h-4" />
            <span class="ml-2">Setting</span>
          </li> -->

          <!-- <vs-divider class="m-1" /> -->

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svg-classes="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import AuthRepo from '@/repositories/auth_repo';

export default {
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.base.AppActiveUser;
    }
  },
  methods: {
    async logout() {
      try {
        await AuthRepo.logout();
        this.$store.dispatch('logout');
      } catch (error) {
        console.log(error.message); // eslint-disable-line
      }
    }
  }
};
</script>
